.helpPopoutCard {
    padding: 16px;
    padding-top: 8px;
    width: 296px;
}

.helpPopoutHeader {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.helpPopoutContent {
    display: flex;
    flex-direction: column;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.helpPopoutSegmentedSelector {
    margin-top: 4px;
    margin-bottom: 8px;
    margin-left: 0px;
    width: 100%;

    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.helpPopoutServiceSelect {
    margin-top: 4px;
    margin-bottom: 8px;
    margin-left: 0px;
    width: 100%;

    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.helpPopoutSegmentedSelector .ant-segmented-item {
    flex-grow: 1;
}

.helpPopoutCalcelButton {
    margin-top: 8px;
    width: 120px;
    background-color: #FFFFFF;
    height: 36px;
    padding: 0px 16px 0px 16px;

    text-align: center;
    font-kerning: none;
    font-feature-settings: "liga" off;

    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.helpPopoutCalcelButton:hover {
  color: #000000D9 !important;
}

.helpPopoutSendButton {
    margin-top: 8px;
    width: 120px;
    background-color: #9236d9;
    height: 36px;
    padding: 0px 16px 0px 16px;

    color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
    text-align: center;
    font-kerning: none;
    font-feature-settings: "liga" off;

    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.helpPopoutSendButton:hover {
    background-color: #a254dd !important;
    color: var(
      --character-title-inverse-85,
      rgba(255, 255, 255, 0.85)
    ) !important;
  }
  