/* Main container for app content */
.appContainer {
  padding-top: 64px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 24px;
  margin-left:48px;
}

.appContainerFull {
  padding-top: 48px;
  margin-left:48px;
  height: calc(100vh);
  width: calc(100vw - 48px);
}

