.senseFarmInfoContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.senseFarmInfoMapContainer {
  height: 148px;
  width: calc(100% - 12px);
  border-radius: 6px;
  margin: 6px
}

.senseFarmInfoMapContainer iframe html body {
  margin: 0px;
}

.senseFarmInfoRiskChartContainer {
  display: grid;
  height: 0px;
}

.senseFarmInfoRiskChart {
  position: relative;
  display: flex;
  align-items: center;
  width: 164px;
  height: 164px;
  background-color: white;
  border-radius: 50%;
  justify-self: center;
  top: -88px;
}

.senseFarmInfoTextContainer {
  padding: 24px 16px 8px 16px;
  display: flex;
  flex-direction: row;
}

.senseFarmInfoTextColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding:8px;
  width: 50%;
}

.senseFarmInfoTextTitle {
  color: var(--Character-Secondary-65, rgba(0, 0, 0, 0.65));
  font-kerning: none;
  font-variant-numeric: lining-nums tabular-nums;

  font-family: "Outfit", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  margin: 0px;
}

.senseFarmInfoText {
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  font-family: "Outfit", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  margin: 0px 0px 16px 0px;
}

.senseFarmInfoTextPurple {
  color: var(--Primary-5, #B64CFF);
  font-kerning: none;
  font-feature-settings: 'liga' off;

  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 150% */
  margin: 0px 0px 16px 0px;
}

.vl {
  border-left: 2px solid #cccccc2f;
  height: auto;
  margin: 0px 16px;
}


.senseFarmInfoTextColumn, .senseFarmInfoText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.senseFarmInfoTextStatus {
  margin-bottom: 16px;

  /* H5/medium */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}