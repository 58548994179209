.visionUpsellDashboardCard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  min-width: 352px;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.custom-legend {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.visionUpsellDashboardCardBody {
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.visionUpsellDashboardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;
  flex-grow: 1;
}

.visionUpsellDashboardTitle {
  color: #fa8c16;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.visionUpsellDashboardDetail {
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.visionUpsellDashboardDate {
  color: #b64cff;
  font-size: 14px;
  font-weight: 400;
}

.visionUpsellDashboardDescription {
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.novisionUpsellDashboardDescription {
  text-align: center;
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.visionUpsellDashboardRecommendation {
  color: #b64cff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.visionUpsellDashboardIcon {
  width: 48px;
  height: 48px;
}

.dividerCustom {
  margin: 8px;
}

.stepNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.stepNavigation .ant-steps-item-icon {
  display: none;
}

.custom-step-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gray;
}

.ant-steps-item-finish .custom-step-circle,
.ant-steps-item-process .custom-step-circle {
  background-color: purple;
}

.popoverContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex
}

.absoluteBox {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.halfWidth {
  width: 50%;
}

.dataItem {
  position: relative;
  padding-left: 20px;
}

.dataItem::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}