.carAreaUsageCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: white;
    width: 49%;
    min-width: 160px;
    min-height: 100%;
    padding: 0px;
    display: "flex",
  }
  
  .carAreaUsageTitle {
    padding: 10px 24px 10px 24px;
  
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* H5/medium */
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  
  .carAreaUsageContent {
    padding: 16px 24px 16px 24px;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .carAreaUsageLegend {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
  .carAreaUsageLegendLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .carAreaUsageLegendDot {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    min-width: 156px;
  }

  .carAreaUsageLegendTitle {    
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* Body/semibold */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }