.notificationsContainer {
  width: 100%;
  height: 100%;
  padding: 0px 16px 4px 16px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: #fff;
}

.notificationsContainerTitle {
  line-height: 4px;
  font-size: 18px;
  color: #b64cff;
}

.notificationsCard {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(193, 193, 193, 0.377);
  margin-bottom: 4px;
  padding: 4px 12px 4px 12px;
  height: auto;
  margin: 0;
  margin-bottom: 4px;
}

.notificationsCardTitle {
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0px;
}

.notificationsCardText {
  color: var(--Character-Secondary-65, rgba(0, 0, 0, 0.65));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
}

.notificationsCardDate {
  color: var(--Character-Secondary-45, rgba(0, 0, 0, 0.45));
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.notificationsCardIcon {
  color: #FFA940;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.esgNotificationsCardIcon {
  color: #F5222D;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationContent {
  max-height: 136px;
  overflow-y: auto;
}

.notificationNoContent {
  display: flex;
  justify-content: center;
  max-height: 136px;
}

.notificationsCardNoContentTitle {
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  text-align: center;

  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}


.notificationsCardNoContent {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
