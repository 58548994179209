.atypicalEventsCard {
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 360px;
  min-width: 352px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.atypicalEventsCardBody {
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.atypicalEventsContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;
  flex-grow: 1;
}

.atypicalEventsTitle {
  color: #fa8c16;

  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;

}

.atypicalEventsDetail {
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.atypicalEventsDate {
  color: #b64cff;
  font-size: 14px;
  font-weight: 400;
}

.atypicalEventsDescription {
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.noAtypicalEventsDescription {
  text-align: center;
  color: #262626;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.atypicalEventsRecommendation {
  color: #b64cff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.atypicalEventsIcon {
  width: 48px;
  height: 48px;
}

.dividerCustom {
  margin: 8px;
}

.stepNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.stepNavigation .ant-steps-item-icon {
  display: none;
}

.custom-step-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gray;
}

.ant-steps-item-finish .custom-step-circle,
.ant-steps-item-process .custom-step-circle {
  background-color: purple;
}
