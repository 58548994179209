.filterButton {
  background-color: #f0f0f0 !important;
  height: 100%;
  border-radius: 6px !important;
  transition: transform 0.5s ease, border-color 0.25s ease;
  cursor: pointer;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden;
  color: black;
}

.filterButton:hover {
  transform: scale(0.95);
  color: black;
}

.filterButton:active {
  transform: scale(1.03);
  color: black;
}

.filterButton.filtered {
  border-color: #9236d9 !important;
  color: black;
}

.filterButton.filtered img {
  filter: brightness(0.5); /* Escurece o ícone */
}

.filterButton .ant-btn {
  width: 100%;
  height: 100%;
  color: black;

  border: none !important; /* Remove default border */
  box-shadow: none !important; /* Remove default box-shadow */
}

.filterButton .ant-btn span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;

}

.filterButton .ant-btn span p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  margin: 4px 0;
  color: black;
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
}

.customMapButton .ant-btn-icon span svg {
  min-width:  16px;
  min-height: 16px;
  flex-shrink: 0;
  color: black;

}

:where(.css-dev-only-do-not-override-usln0u.customMapButton).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: black;
  background: #4096ff;
}

.mapButtonLabel {
  color: var(--Primary-6, #9236D9);
  text-align: center;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;

  /* Footnote/description */
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}