.mapboxContainer {
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  width: -webkit-fill-available;
  justify-content: space-between;
  height: -webkit-fill-available;
}

.mapBoxVis {
  width: 100%;
  padding: 4px;
}

.mapBoxSkelly {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 6px;
}

.leaflet-control-attribution {
  display: none;
}