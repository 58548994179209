.TopFrame {
  display: flex;
  align-items: center;
  height: 48px;
  background: var(--Colors-Secondary-800, #262626);
  color: #fff;
  width: 100vw;
  padding: 4px 0;
  z-index: 1100;
  position: fixed;
  justify-content: space-between;
}

.topFrameLogoContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.TopFrame img {
  height: 32px;
}

.topBarLogout {
  margin-left: auto;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

.logoutButton {
  color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
  text-align: center;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  border: none;
}

.logoutButton:hover {
  color: #9236d9;
}

.TopFrameLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: var(--Colors-Secondary-800, #262626);
  color: #fff;
  width: 100%;
  padding: 4px 0;
}

.TopFrameLogin img {
  height: 32px;
}
