.mapVis {
  width: 100%;
  height: 100%;
}

.visionDashboardPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.visionDashboardMapPopoverInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
}

.visionDashboardProposalIdTitle {
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.visionDashboardProposalId {
  color: var(--Primary-5, #B64CFF);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.visionDashboardPopupArea {
  color: var(--Character-Secondary-65, rgba(255, 255, 255, 0.65));

  /* Nunito Sans/Body/Large */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.sensePopupInfoText {
  display: flex;
  gap: 4px;
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));

  /* Nunito Sans/Label/Large */
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.visionDashboardPopupButton {
  max-width: 168px !important;
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));
  background-color: var(--Neutral-1, #141414);
  text-align: center;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;

  /* Footnote/description */
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.visionDashboardPopupButton:hover {
  color: #9236d9 !important;
  background-color: var(--Neutral-1, #141414) !important;
}

.visionDashboardPopup .leaflet-popup-content-wrapper {
  width: 288px;
  height: auto;
  border-radius: 4px;
  background: var(--Neutral-1, #141414);

  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}

.visionDashboardPopup .leaflet-popup-tip {
  background: var(--Neutral-1, #141414);
}

.visionDashboardPopup .leaflet-popup-close-button {
  color: #FFFFFFD9 !important;
}

.visionDashboardPopup .leaflet-popup-content {
  margin: 0; 
}