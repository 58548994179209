.loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    width: 100%;
  }
  
  .loadingPageCarouselContainer {
    height: 100px;
    width: 300px;
    background-color: #FAFAFA;
  }
  
  .loadingPageMessage {
    color: var(--Character-Primary-85, #000000D9);  ;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }
  
  .ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 64px;
    background: #FAFAFA;
    overflow: hidden;
  }
  
  .loadingPageSpin {
    font-size: 144px !important;
    color: #B64CFF;
  }

  .colorSpin {
    color: #B64CFF;
  }