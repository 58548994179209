.homeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.homeTitle {
    color: var(--Primary-5, #B64CFF);
    text-align: center;
    font-family: Outfit;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
}


.homeUserGuide {
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    padding: 8px 24px 24px 24px;
    flex-direction: column;
    align-self: center;
    gap: 12px;
    max-width: 1696px;
}

.homeUserGuideTitle {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.homeUserGuideContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
}

.UserGuidePage {
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    object-fit: contain;
    align-self: stretch;
    width: 100%;
}