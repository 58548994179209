.breadcrumbs {
    margin-bottom: 20px;
    color: var(--Character-Title-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;

    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}