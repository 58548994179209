/* Sidebar styles */
.SideBar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 48px;
  background: var(--Colors-Secondary-800, #262626);
  color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  z-index: 1001;
}

.SideBarButton {
  width: 48px;
  height: 48px;
  margin: 4px 0;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideBarButton.active {
  outline: none;
  background-color: #9236d9;
}

.SideBarButton:hover {
  outline: none;
  background-color: #CD75FF;
}

.SideBarButton img {
  filter: invert(0);
}

.kml-upload-icon {
  width:  36px;
  height: 36px;
  border-radius: 50%;
  background-color: #9236D9;
}
