.sidebar {
    height: calc(100% - 48px);
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 88px;
    left: 0;
    background-color: #1e1e1e;
    overflow-x: hidden;
    overflow-y: auto; /* Ensure vertical scrolling */
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    transition-timing-function: ease-in-out;
}

/* WebKit browsers */
.sidebar::-webkit-scrollbar {
    width: 8px;
}

.sidebar::-webkit-scrollbar-track {
    background: #141414;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
    border: 2px solid #141414;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Firefox */
.sidebar {
    scrollbar-width: thin;
    scrollbar-color: #444 #141414;
    left: 48px;
}

.sidebar.open {
    width: 376px;
}

.sidebar a {
    padding-top: 32px;
    padding-left: 60px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar-header {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    color: #ffffffd9;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    border-bottom: solid #424242 1px;
}

.sidebar-header-title {
    color: #ffffffd9;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.sidebar-header .ant-tabs {
    margin-top: 0;
}

.sidebar-body {
    color: #ffffff;
    width: 100%;
    padding: 24px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sidebar-body a {
    text-decoration: none;
    color: #818181;

    display: block;
}

.sidebar-body a:hover {
    color: #f1f1f1;
}

.sidebar-body .ant-tabs {
    color: #ffffff;
}

.sidebar-body .ant-tabs-tab {
    font-size: 16px;
}

.sidebar-body .ant-tabs-tab-active {
    color: white;
    font-weight: bold;
}

.sensePlusFilterSelect .ant-select-selector {
    background-color: #262626 !important;
    color: #ffffffd9 !important;
    border: none !important;
    height: 44px !important;
    border-radius: 2px !important;
    font-family: "Outfit", sans-serif;
}

.sensePlusFilterSelect .ant-select-selection-placeholder {
    color: #8c8c8c !important;
}

.sensePlusSidebarCounter {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.sensePlusSidebarSwitchLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffffd9;
}

.sense-plus-sidebar-chart-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 4px;
    background-color: #141414;
}

.sense-plus-chart-tab .ant-tabs-tab {
    color: #bc6ce9 !important;
    font-family: "Outfit", sans-serif;
    background-color: transparent;
}

.sense-plus-chart-tab .ant-tabs-tab.ant-tabs-tab-active {
    color: #bc6ce9 !important;
    background-color: transparent;
    font-weight: bold;
}

.sense-plus-chart-tab .ant-tabs-tab:hover {
    color: #bc6ce9 !important;
}

.sense-plus-chart-tab .ant-tabs-nav::before {
    border-bottom: 1px solid #141414 !important;
}

.sense-plus-sidebar-table {
    border: solid 1px #424242;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
