.sense-plus-bottom-bar {
    position: fixed;
    bottom: 0;
    background-color: #141414;
    color: white;
    z-index: 990;
    transition: height 0.5s, width 0.5s;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    width: 100%;
    transition: 0.5s;
}

.sense-plus-bottom-bar-header {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background-color: #141414;
    border-bottom: 1px solid #444;
    z-index: 1010;
}

.sense-plus-bottom-bar-body {
    padding: 16px;
    color: white;
    overflow-y: auto;
    height: 100%;
}

.sense-plus-bottom-bar.open {
    height: 200px;
}

.sense-plus-bottom-bar p {
    margin: 0;
    padding: 16px;
    font-size: 16px;
}

.sense-plus-bottom-bar-buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    position: fixed;
    z-index: 1100;
    right: 24px;
    /* transition: bottom 0.5s; */
}

.toggle-sense-plus-bottom-bar {
    background-color: #9f45dd;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.toggle-sense-plus-bottom-bar:hover {
    background-color: #7d36b0 !important;
    color: white !important;
}

.ticket-Table-CSV {
    background-color: #9500c700 !important;
    border: 1px solid #ffffff !important;
    box-shadow: none !important;
    color: white !important;
}
