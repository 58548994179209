.stepTracker {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 572px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.step {
  display: flex;
  align-items: center;
}

.stepNumber {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  color: #ddd;
  position: relative;

  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.stepNumberChecked {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9236D9;
  border-radius: 50%;
  color: #9236D9;
  position: relative;
}

.stepLabel {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  max-width: 120px;

  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 8px;
}

.stepActive .stepNumber {
  border-radius: 50%;
  background-color: #9236d9;
  border-color: #9236d9; /* Make border color match active background */
  color: #fff; /* Make text color white for active step */
}

.stepActive .stepLabel {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
}
