.billingChartCard {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: white;
    overflow: hidden;
    padding: 20px 24px 8px 12px;
  }

.billingChartTitle {
    margin-left: 12px;
    color: var(--character-secondary-65, rgba(0, 0, 0, 0.65));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
  
.billingChartNumber {
    margin-left: 12px;
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

    /* H2/medium */
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */
}

.billingVariationContent {
    margin-left: 12px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px
}

.billingVariationText {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}