.cardTitle {
    font-family: Outfit, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: #7024B3;
    margin-bottom: 16px;
}

.billingContainer {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
    padding: 4px;
    gap: 16px;
  }

.flexItem {
    flex: 1;
}

.billingElementFlexHorizontal {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    gap: 16px;
}
  
.billingElementFlexVertical {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.equalHorizontalCards {
    display: flex;
    flex-direction: row;
    gap: 16px;
}
  
.equalHorizontalCards > div {
    flex: 1;
}
