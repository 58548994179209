.mapVis {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.sensePlusFilterCard {
    width: 100%;

    background: var(--Neutral-2, #262626);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: column;
}

.sensePlusFilterContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    color: var(--Character-Primary-85, #ffffffd9);
    font-kerning: none;
    font-feature-settings: "liga" off;

    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.sensePlusFilterContent div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.sensePlusFilterContainer {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 360px;
    background-color: none;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sensePlusFilterButton {
    width: 44px !important;
    height: 44px;
    border-radius: 2px;
    border: none;

    color: #ffffffd9;
    background-color: #262626;
}

.sensePlusFilterButton:hover {
    color: #9236d9 !important;
    background-color: #262626 !important;
}

.sensePlusFilterButtonPiePannel {
    width: 44px !important;
    height: 44px;
    border-radius: 2px;
    border: none;
    color: #ffffffd9;
    background-color: transparent;
}

.sensePlusFilterButtonPiePannel:hover {
    color: #9236d9 !important;
    background-color: transparent !important;
}

.sensePlusSearch {
    height: 44px;
    border-radius: 2px;
    border: none;

    color: #ffffffd9;
    background-color: #262626;
}

.sensePlusSearch input {
    height: 44px !important;
    color: #ffffffd9;
    font-family: Outfit;
    background-color: #262626;
    border: none;
    border-radius: 2px;
}

.sensePlusSearch input::placeholder {
    color: #8c8c8c;
}

.sensePlusSearch:hover,
.sensePlusSearch input:hover {
    border-color: #9236d9 !important;
    background-color: #262626;
}

.sensePlusSearch input:focus {
    color: #ffffffd9;
    background-color: #262626;
    outline: none;
    box-shadow: none;
    border: none;
}

.sensePlusSearch .ant-btn {
    height: 44px;
    border-radius: 0 2px 2px 0;
    background-color: #262626;
    color: #ffffffd9 !important;
    border: none;
}

.sensePlusSearch .ant-btn:hover {
    color: #9236d9;
    background-color: #262626 !important;
}

.sensePlusSearchDropdown {
    background-color: #141414 !important;
    font-family: Outfit;
    color: #ffffffd9;
}

.sensePlusSearchDropdown .ant-select-item {
    background-color: #141414 !important;
    color: #ffffffd9 !important;
}

.sensePlusSearchDropdown .ant-select-item-option-active {
    background-color: #262626 !important;
}

.sensePlusSegmented {
    background-color: #1e1e1e !important;
}

.sensePlusSegmented .ant-segmented-item-selected {
    background-color: #141414 !important;
    color: #ffffffd9 !important;
    transition: background-color 0.3s ease !important;
}

.sensePlusSegmented .ant-segmented-item {
    color: #ffffffd9;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sensePlusSegmented .ant-segmented-item:hover {
    background-color: #333333 !important;
    color: #ffffffd9 !important;
}

.sensePlusSegmented .ant-segmented-item-disabled {
    color: #888888 !important;
}

.sensePlusSegmented .ant-segmented-item-selected:hover {
    background-color: #141414 !important;
}
.sensePlusSegmented .ant-segmented-item:not(.ant-segmented-item-selected) {
    transition: background-color 0.3s ease !important;
}

.sensePlusSegmented .ant-segmented-thumb {
    background-color: #141414 !important;
}

.senseMapButtonLabel {
    display: flex;
    align-items: center;
    gap: 6px;
    color: white !important;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.ant-segmented-item-selected .senseMapButtonLabel {
    color: #9236d9 !important;
}

.ant-segmented .ant-segmented-item {
    position: relative !important;
    display: flex !important;
    align-items: stretch !important;
    justify-items: flex-start !important;
    width: 100% !important;
}

#main {
    transition: 0.1s;
    transition-timing-function: ease-in-out;
}

#main > div {
    position: relative;
    height: 100%;
    width: 100%;
}

.sense-plus-sidebar-chart-header {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ticket-table {
    background-color: #1f1f1ffd;
    border: 1px solid #3e3e3e;
    border-radius: 12px;
}

.ticket-table .ant-table-thead > tr > th {
    background-color: #262626;
    color: white;
    text-align: left;
    font-weight: bold;
    border: 1px solid #3e3e3e;
}

.ticket-table .ant-table-tbody > tr > td {
    text-align: left;
    font-size: 14px;
    color: #ffffff;
    background-color: #141414;
    border: 1px solid #3e3e3e;
    border-style: hidden hidden solid hidden;
}

.ticket-table .ant-table-cell {
    background-color: #141414 !important;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffd9 !important;
    border-style: hidden hidden solid hidden;
}

.ticket-table .ant-table-tbody > tr:hover > td {
    background-color: #333333 !important;
}

.ticket-table .ant-btn-link {
    font-family: Outfit !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #9f45dd !important;
    font-weight: bold;
    border: none !important;
}

.ticket-table .ant-btn-link:hover {
    color: #b274db !important;
}

.ticket-table .ant-table-body {
    background-color: #141414 !important;
}

.ticket-table .ant-table-thead > tr > th {
    border-right: none !important;
    border-left: none !important;
    position: relative;
}

.ticket-table .ant-table-thead > tr > th::before,
.ticket-table .ant-table-thead > tr > th::after {
    content: none !important;
}
