.esgTrackingCarChartCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: white;
    width: 49%;
    min-width: 160px;
    min-height: 100%;
    padding: 0px;
    display: "flex",
  }
  
  .esgTrackingCarChartTitle {
    padding: 10px 24px 10px 24px;
  
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* H5/medium */
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  
  .esgTrackingCarChartContent {
    padding: 16px 24px 16px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .esgTrackingCarChartStatus {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
  }
  
  .esgTrackingCarChartDescription {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;  
  }

  .esgTrackingCarChartCarCodes {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000A6;
  }