/* CardComponent.css */
.listingCard {
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  height: 80px;
  padding-left: 24px;

  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  justify-content: space-between;
}


.deleteTicketButton {
  border: none !important;
  margin-left: -16px;
  margin-right: -8px;
}

.deleteTicketButton:hover {
  color: red !important;
}

.listingCard-starIcon {
  cursor: pointer;
}

.listingCard-starFilled {
  font-size: 24px;
}

.listingCard-starOutlined {
  font-size: 24px;
}

.listingCardRiskScore {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.listingCardRiskScoreError {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
  background-color: #FF0026;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.listingCardRiskScoreCalculating {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #B64CFF;
  font-size: 40px;
}

.listingCard-data {
  display: flex;
  flex-wrap: wrap;
}

.listingCard-dataVertical {
  display: flex;
  flex-direction: column;
}

.listingCardButtonArea {
  min-width: 120px;
  display: flex;
  justify-content: right;
  gap: 8px;
}

.listingCard-buttonIcon {
  vertical-align: middle;
}

.listingCardMap {
  width: 134px;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.textEllipsisGray {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.65));

  font-family: "Outfit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

  font-family: "Outfit", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.textEllipsisTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

  font-family: "Outfit", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.approveDisapproveButton {
  border-radius: 50%;
  height: 40px;
  width: 40px !important;
}

.monitorButton {
  height: 40px;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
}


