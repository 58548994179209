.consultedHectaresCard {
  display: flex;
  flex-direction: column;
  height: 432px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 0px 24px 8px 24px;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  width: 100%;
}

.elementFlexHorizontalConsultedHectares {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  justify-content: space-between;
}

.consultedHectaresTableTitle {
  margin-bottom: 20px;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.consultedHectaresTableItem {
  padding: 8px !important;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}