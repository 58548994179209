.reportContainer {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: auto;
  padding: 4px;
  gap: 16px;
}

.elementFlexHorizontal {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  justify-content: space-between;
}

.elementFlexHorizontalSense {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  justify-content: space-around;
}

.elementFlexVertical {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.element {
  background-color: #26272c02;
  height: 100%;
  width: 100%;
  border-radius: 6px;
}

/* Dashboard Header Styles */
.reportHeaderContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reportHeaderContent {
  display: flex;
  justify-content: space-between;
  /* Space children to opposite ends */
  align-items: center;
  width: 100%;
}

.reportHeaderTexts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Start-align texts vertically */
}

/* Common styles for title and text */
.reportHeaderTitle,
.visionReportHeaderText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reportHeaderTitle {
  margin-top: 0;
  font-size: 24px !important;
  font-weight: 700;
  margin-bottom: 0px !important;
}

.visionReportHeaderText {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: normal;
}

.visionPdfButton {
  height: 40px;
  border-radius: 2px;
  border: 1px solid var(--Primary-6, #9236D9);
  background: var(--Primary-6, #9236D9);

  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  color: var(--Neutral-1, #FFF);
  text-align: center;
  
  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.visionPdfButton:hover {
  background-color: #a254dd !important;
}

.reportElementCard {
  padding: 16px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
}

.flex-container {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: auto;
  padding: 4px;
  gap: 16px;
  border: 2px solid black;
}

.flex-element-horizontal {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  border: 2px solid red;
}

.flex-element-vertical {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 2px solid blue;
}
