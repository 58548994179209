.auraHeaderContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.auraHeaderContent {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.auraHeaderRiskScore {
  display: inline-block;
  min-width: 64px;
  width: 64px;
  min-height: 64px;
  height: 64px;
  /* line-height: 64px; */
  border-radius: 50%;
  color: #ffffffd9;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 64px;
  text-align: center;
}

.auraHeaderField {
  font-family: Outfit;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #000000a6;
}

.auraHeaderValue {
  max-width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #000000d9;
}

.auraHeaderLastValues {
  max-width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000a6;
}

.auraPdfButton {
  height: 36px;
  width: 136px;
  border-radius: 2px;
  border: 1px solid var(--Primary-6, #9236d9);
  background: var(--Primary-6, #9236d9);

  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  color: #ffffffd9;
  text-align: center;

  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.auraPdfButton:hover {
  background-color: #a254dd !important;
}

@media only screen and (max-width: 1370px) {

  .auraHeaderContent {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  }

  .auraHeaderContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}
