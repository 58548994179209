.visionUpsellCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: white;
    width: 420px;
    min-width: 240px;
    padding: 0px;
  }
  
  .visionUpsellChartTitle {
    padding: 16px 24px 16px 24px;
  
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* H5/medium */
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  
  .visionUpsellChartContent {
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .visionUpsellChartContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 320px;
    align-items: center;
  }
  
  .visionUpsellChart{
    width: 100%;
    max-height: 320px;
    min-height: 100px;
    aspect-ratio: 1;
  }
  
  .visionUpsellLegend {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90%;
  
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
  .visionUpsellLegendLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .visionUpsellLegendDot {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    min-width: 136px;
  }
  
  .visionUpsellLegendAlignRight {
    text-align: right;
    min-width: 68px;
  }