.AppHeader {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.AppLink {
    color: #61dafb;
}

.reproveButton{
    border-radius: 4px;
    border: 1px solid var(--Character-danger, #FF4D4F);
    background: var(--Neutral-3, #F5F5F5);
    
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: #FF4D4F !important;
    border-color: #FF4D4F !important;
    height: 40px;    
    padding: 6px 16px 6px 16px;
    gap: 10px;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }

.reproveButton:hover {
    color: #ff6d70 !important;
    border-color: #ff6d70 !important;
}
  
.approveButton{
    border-radius: 4px;
    border: 1px solid var(--Character-success, #52C41A);
    background: var(--Character-success, #52C41A);

    /* drop-shadow/button-primary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    height: 40px;
    color: white!important;
    padding: 6px 16px 6px 16px;
    gap: 10px;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.approveButton:hover {
    background-color: #7dd651 !important;
    border-color: #7dd651 !important;
}


.monitorReportButton {
    height: 40px;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    padding: 6px 16px 6px 16px;
}

.pdfButton {
    height: 40px;
    border-radius: 2px;
    border: 1px solid var(--Primary-6, #9236D9);
    background: var(--Primary-6, #9236D9);
  
    /* drop-shadow/button-primary */
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  
    color: var(--Neutral-1, #FFF);
    text-align: center;
    
    /* H5/regular */
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}

.pdfButton:hover {
    background-color: #a254dd !important;
}

.auraHeaderButtons {
    display: flex;
    flex-direction: row;
    gap: 16px;
}