.usersContainer {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
    padding: 4px;
    gap: 16px;
    margin-bottom: 24px;
  }

.usersTitle {
    color: var(--Primary-5, rgba(112, 36, 179, 1));
    text-align: left;
    font-family: Outfit;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-bottom: -10px;;
}

.ticketListingBar {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    margin-bottom: 24px;
}

.buttonCustom {
    background-color: var(--Primary-5, rgba(112, 36, 179, 1));
}
