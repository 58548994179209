.monthlyCostCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  width: 540px;
  min-width: 340px;
  min-height: 100%;
  padding: 0px;
}

.monthlyCostChartTitle {
  padding: 16px 24px 16px 24px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.billingCostChartContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.monthlyCostChartSubtitle {
  padding: 8px 0px 32px 0px;

  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.billingCostChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.monthlyCostChart{
  width: 90%;
  max-height: 340px;
  aspect-ratio: 1;
}

.monthlyCostLegend {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 85%;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 150% */
}

.monthlyCostLegendLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.monthlyCostLegendDot {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  min-width: 108px;
}

.monthlyCostLegendAlignRight {
  text-align: right;
  min-width: 68px;
}