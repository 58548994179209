.errorBoundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
}

.errorIcon {
  font-size: 48px;
  color: red;
  margin-bottom: 16px;
}

.errorTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.errorDescription {
  font-size: 16px;
  margin-bottom: 24px;
}
