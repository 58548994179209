/* Ticket listing styles */
.ticketListingBar {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
}

.emptyTicketHistory {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  gap: 48px;

  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  text-align: center;

  /* H5/medium */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.loadMoreButton {
  border: none !important;
  margin-top: 32px;

  display: inline-flex;
  height: 36px;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  color: var(--Primary-6, var(--Primary-6, #9236D9));
  text-align: center;

  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.loadMoreButton:hover {
  color: #a254dd !important;
}

.selectorBox {
  display: flex;
  font-size: 28px;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
}

.listCardLoadingSkeleton .ant-card-body {
  padding: 0;
  width: 100%;
  height: 80px;
}

.listCardLoadingSkeleton {
  min-width: 1080px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
  padding-left: 64px;
  padding-right: 64px;
}

.refreshTicketListing {
  font-size: 28px;
  transform: rotate(270deg);
  color: #000000D9
}

.refreshTicketListing:hover {
  font-size: 28px;
  transform: rotate(270deg);
  color: #9236d9
}

/* Ant Design Overrides for purple theme in ticket list */
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus-within,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused.ant-select:not(.ant-select-disabled).ant-select-open .ant-select-selector,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:focus-within,
.ant-checkbox-wrapper:focus-within,
.ant-input-group-addon:hover .ant-input-search-button,
.ant-input-group-addon:focus-within .ant-input-search-button {
  border-color: #9236d9 !important;
}

.ant-checkbox-wrapper input[type="checkbox"]:checked + .ant-checkbox-inner {
  background-color: #9236d9 !important;
}

.ant-input-search-button svg path {
  transition: fill 0.3s ease;
}

.ant-input-search-button:hover svg path,
.ant-input-search-button:focus-within svg path {
  fill: #9236d9 !important;
}
