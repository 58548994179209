.customDragger .ant-upload-list {
  position: relative;
  top: 70px; 
}

.kmlUploadCard {
  min-width: 768px;
  align-self: center;
  align-content: center;
}

.kmlUploadForm {
  display: flex;
  flex-direction: column;
}

.kmlUploadFormTitle {
  color: var(--Character-Title-85, rgba(0, 0, 0, 0.85));
  text-align: start; /* Alinha o texto à esquerda */
  align-self: start; /* Garante alinhamento interno à esquerda */
  font-family: "Outfit", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
}

.kmlUploadHeader p {
  color: var(--Primary-7, #7024b3);

  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 36px;
  font-style: normal;
  line-height: 4px; /* 133.333% */
}

.buttonCustom {
  background-color: #9236d9;
  height: 36px;
  padding: 0px 16px 0px 16px;

  color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
  text-align: center;
  font-kerning: none;
  font-feature-settings: "liga" off;

  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.buttonCustom:not(:disabled):hover {
  background-color: #a254dd !important;
  color: var(
    --character-title-inverse-85,
    rgba(255, 255, 255, 0.85)
  ) !important;
}

.buttonCustomWhite {
  background-color: #ffffff;
  height: 36px;
  padding: 0px 16px 0px 16px;

  text-align: center;
  font-kerning: none;
  font-feature-settings: "liga" off;

  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.buttonCustomWhite:hover {
  color: #000000d9 !important;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
  /* Align buttons to the right */
  gap: 8px;
  /* Space between buttons */
  font-family: "Outfit", sans-serif;
}

.ant-upload button {
  height: 34px;
}

.ant-result-title {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 36px;
}

.kmlFileRenderButton:hover {
  color: #b64cff !important;
}

.customDivider {
  border-top-color: #d9d9d9;
}

.kmlFileRender {
  display: flex;
  flex-direction: row;
  width: 890px;
  align-items: flex start;
  justify-content: space-between;

  margin-top: 40px;
  margin-bottom: 16px;
}

.removeFileButton {
  border: none !important;
  padding: 4px;
  padding-right: 0px;
  margin-left: 16px;
  color: var(--Character-danger, #ff4d4f);
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.removeFileButton:hover {
  color: #ff8486 !important;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fieldNameForm {
  margin-bottom: 0px;
  width: 100%;
}

.fieldNameLabel {
  min-width: 122px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: right;

  /* Body/semibold */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  margin-bottom: 4px;
}

.ant-upload-text{
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}

.ant-upload-hint{
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  
}

.fieldNameInput {
  width: 100%;
  margin-bottom: 4px !important;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.serviceSelector .ant-select-selection-placeholder,
.cropTypeSelector .ant-select-selection-placeholder,
.soilTypeSelector .ant-select-selection-placeholder,
.cycleTypeSelector .ant-select-selection-placeholder {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.65)) !important;
  font-kerning: none !important;
  font-feature-settings: "liga" off !important;
  font-family: Outfit !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 24px !important; /* 150% */
}

.serviceSelector {
  min-width: 100px;
  min-height: 26px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.uploadFileName {
  color: #9236d9;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.cropTypeSelector {
  width: 120px;
  min-width: 120px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.soilTypeSelector {
  width: 122px;
  min-width: 122px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.cycleTypeSelector {
  width: 128px;
  min-width: 128px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.proposalIdInput {
  width: 162px;

  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.kmlUploadContent {
  color: var(--character-secondary-65, rgba(0, 0, 0, 0.65));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  margin-bottom: 16px;
}