*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: "SF Mono", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
  font-size: 1em;
  background-color: #eee;
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.ant-input-outlined:hover{
  border-color: #9236d9;
}

.ant-btn:hover{
  border-color: #9236d9!important;
}
