.historicalParamsContainer {
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  overflow-x: auto;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
}

.historicalParamsCard {
  min-width: 300px;
  min-height: 400px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
}

.historicalParamsColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  min-width: 128px;
}

.historicalParamsTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 0px;
  color: #000000;
}

.historicalParamsText {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #b64cff;
}

.highCloudCoverageTitle {
  max-width: 190px;
  color: var(--Primary-5, #B64CFF);
  text-align: center;
  
  /* H3/medium */
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 128.571% */
}

.highCloudCoverageDescription {
  max-width: 180px;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  
  /* Footnote/description */
  font-family: Outfit;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 133.333% */
}