.seccao {
    display: flex;
    gap: 20px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.farmInfoTextColumn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    width: 12vw;
}
.custom-scroll-container {
    overflow-x: auto;
    direction: rtl;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #9236D9 #f0ecec;
}

.custom-scroll-container::-webkit-scrollbar {
    height: 8px; /* For Chrome, Safari, and Opera */
}

.custom-scroll-container::-webkit-scrollbar-track {
    background: #f0ecec;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 2px solid #f0ecec;
}
.senseBasicTrueImageDisclaimer {
    color: #000000A6;
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    max-width: 270px;
}

.senseBasicReportSelectorCard {
    position: absolute;
    bottom:32px;
    padding: 8px 16px 8px 16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 8px;
    width: calc(100% - 64px);
    min-height: 64px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    /* Change this to row for horizontal alignment */
    justify-content: space-between;
    align-items: center;
}

.DoubleLeftOutlined,
.DoubleRightOutlined {
    color: #000000;
    font-size: 18px;
}

.LeftOutlined,
.RightOutlined {
    color: #000000;
    font-size: 18px;
}

/* Customizing the color of the current step */
.custom-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-icon {
    background-color: #9236D9 !important; /* Active step color */
    border-color: #9236D9 !important;
}

/* Customizing the color of completed steps */
.custom-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #9236D9 !important; /* Finished step color */
    border-color: #9236D9 !important;
}

/* Customizing the color of default steps */
.custom-steps .ant-steps-item .ant-steps-item-icon {
    background-color: #9236D9 !important; /* Default step color */
    border-color: #9236D9 !important;
}

.custom-steps .ant-steps-item-icon-dot {
    background-color: #9236D9 !important; /* Default step color */
    border-color: #9236D9 !important;
}

/* Customizing the color of step titles */
.custom-steps .ant-steps-item .ant-steps-item-title {
    font-family: Outfit !important;
    color: #b64cff !important; /* Title color for default steps */
}

/* Customizing the title color for the current step */
.custom-steps .ant-steps-item.ant-steps-item-process .ant-steps-item-title {
    font-weight: bolder;
    font-family: Outfit !important;
    color: #b64cff !important; /* Title color for the current active step */
}

/* Customizing the color of the step description */
.custom-steps .ant-steps-item .ant-steps-item-description {
    font-family: Outfit !important;
    color: #0f0f0f !important; /* Custom description color */
}

/* Customizing the color of the step connector line */
.custom-steps .ant-steps-item .ant-steps-item-tail {
    background-color: rgba(146, 54, 217, 0.4) !important; /* Custom connector line color */
}

/* Customizing the dot color for different states */

/* Target the default dot */
.custom-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon-dot {
    background-color: #8c8c8c !important; /* Default dot color */
}

/* Target the current step's dot */
.custom-steps .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
    background-color: rgba(146, 54, 217, 0.49) !important; /* Current step color */
}

/* Target the finished step's dot */
.custom-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon-dot {
    background-color: #9236D9 !important; /* Finished step color */
}

.ant-radio-button-wrapper-checked {
    background-color: #9236D9 !important; /* Custom background color */
    border-color: #9236D9 !important; /* Custom border color */
    color: #fff !important; /* Custom text color */
}

.ant-radio-button-wrapper-checked:hover {
    background-color: #9236D9 !important; /* Slightly darker background on hover */
}

.ant-radio-button-wrapper {
    font-family: Outfit;
    color: #000; /* Unselected text color */
    border-color: #d9d9d9; /* Unselected border color */
    background-color: rgba(217, 217, 217, 0.52); /* Unselected background color */
}

.ant-radio-button-wrapper:hover {
    color: #9236D9;
    border-color: rgba(146, 54, 217, 0.49);
}

.senseBasicButtons {
    display: flex;
    flex-direction: column;
}

.senseBasicMapLegendLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.senseBasicLegendCard {
    position: absolute;
    top: 24px;
    left: 24px;
    background-color: white;
    padding: 16px 24px 16px 24px;
    border-radius: 4px;
    min-width: 224px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.senseBasicLegendContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.senseBasicLegendTitle {
    color: var(--Character-Title-85, rgba(0, 0, 0, 0.85));
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.evolutionChartTitle {
    color: var(--Character-Title-85, rgba(0, 0, 0, 0.85));
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.senseBasicMapLegendSquare {
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.senseBasicLegendText {
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.senseBasicMapLegendLine {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}


.senseBasicDownloadReportContainer {
    display: flex;
    flex-direction: column;
}

.senseBasicDownloadReportButton {
    height: 40px;
    border-radius: 8px;
    background: #9236D9;
    color: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.senseBasicDownloadReportButton:hover {
    background-color: #a254dd !important;
}

.datePickerSpacer {
    flex: 1;
}

.pdfButtonSenseBasic {
    height: 40px;
    border-radius: 8px;
    background: #9236D9;
    color: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-right: 20px; /* Adjust the value as needed */
}

.pdfButtonSenseBasic:hover {
    background-color: #a254dd !important;
}

.pdfButton:hover {
    background-color: #a254dd !important;
}

.precipitationChartButtonSenseBasic {
    height: 40px;
    border-radius: 8px;
    background: #9236D9;
    color: #FFF;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    display: block; /* Ensure the button is treated as a block element */
    margin: 0 auto; /* Center the button horizontally */
}

.precipitationChartButtonSenseBasic:hover {
    background-color: #a254dd !important;
}

.ellipsisSenseBasic {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch; /* Limit to 20 characters */
}

.monitorReportButton {
    height: 40px;
    background: #fafafa;
    color: #000000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    align-items: right;
    box-shadow: none;
}

.modalContentStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}

.evolutionChartContainer {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 8px;
}

.infoSectionRainfallSum {
    color: #b54cff;
    font-family: Outfit;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 6px;
}

.infoSectionMaxTemp {
    color: #b54cff;
    font-family: Outfit;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 6px;
}

.infoSectionMinTemp {
    color: #b54cff;
    font-family: Outfit;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 6px;
}

.infoSectionAvgTemp {
    color: #b54cff;
    font-family: Outfit;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 6px;
}

.infoSectionMaxWind {
    color: #b54cff;
    font-family: Outfit;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 6px;
}

.farmInfoTextTitleSenseBasic {
    font-size: 12px;
    font-weight: 600;
    line-height: 0px;
    color: #000000a6;
    margin-top: 20px;
}

.ant-drawer-content-wrapper,
.ant-drawer-content {
  box-shadow: none !important;
}
