.auraTabText {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000D9;
}
  
.ant-tabs-tab.ant-tabs-tab-active .auraTabText {
    color: #9236D9; /* Purple color when selected */
}
  
.ant-tabs-tab:hover .auraTabText {
    color: #9236D9; /* Optional: Purple color on hover */
}
  
/* Ensuring the font size remains consistent */
.ant-tabs-tab .auraTabText {
    font-size: 16px;
}

.auraEsgContainer {
    width: 60%;
    min-width: 540px;
    padding: 16px 8px 16px 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFFFFF;
}

.auraEsgCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.auraEsgContent {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    gap: 8px;
}

.auraEsgCard {
    width: calc(33% - 4px);
    height: calc(25% -4px);
    padding: 16px 8px 16px 8px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: left;
    text-align: center;
    background-color: #FFFFFF;
}


.auraEsgCardIcon {
    display: flex;
    font-size: 20px;
}

.auraEsgCardText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000000D9;
}

.auraRainfallContainer {
    width: 100%;
    padding: 16px 16px 24px 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #FFFFFF;
}

.auraRainfallCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.auraInsightsContainer {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #B64CFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFFFFF;
}

.auraInsightsCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #B64CFF;

    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.auraInsightsContent {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    white-space: pre-line;

    max-height: 200px;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000A6;
    overflow-y: scroll;
}

.auraInsightsContentHeader {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #000000D9;
}

.auraInsightsButton {
    border-radius: 2px;
    border: none;
    background: var(--Primary-6, #B64CFF);

    color: #FFFFFFD9;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.auraInsightsButton:hover {
    background-color: #c477f7 !important;
    color: #FFFFFFD9 !important;
}


.auraClimaticRiskContainer {
    width: 100%;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFFFFF;
}

.auraClimaticRiskTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.auraInfoTitle {
    flex-wrap: wrap;
    white-space: pre-line;
    font-family: Outfit;
    font-weight: 400;
    text-align: left;
    color: #000000a6;
}

.auraInfoValue {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #b64cff;
}

.auraFieldAnalysisContainer {
    width: 100%;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFFFFF;
    height: 216px;
}

.auraFieldAnalysisCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.auraFieldAnalysisContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 8px;

    height: 100%;
    width: 100%;
    gap: 16px;
}

.auraFieldAnalysisColumn {
    width: calc(50% - 12px);
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: left;
}

.auraFieldAnalysisSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.auraFieldAnalysisTitle {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000A6;
}

.auraFieldAnalysisValue {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #9236D9;
}

.auraClimateAnalysisContainer {
    width: 100%;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFFFFF;
}

.auraClimateAnalysisCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #000000D9;
}

.auraClimateAnalysisContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 8px;
    padding-top: 8px;

    height: 100%;
    width: 100%;
    gap: 16px;
}

.auraClimateAnalysisSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.auraClimateAnalysisTitle {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000A6;
}

.auraClimateAnalysisValue {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #9236D9;
}

.auraAnalysisInsightsContainer {
    width: 100%;
    height: 100%;
    padding: 16px 24px 16px 24px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid #B64CFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFFFFF;
}

.auraAnalysisInsightsCardTitle {
    font-family: Outfit;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    
    color: #000000D9;

    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.auraAnalysisInsightsContent {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    white-space: pre-line;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000A6;
}

.auraAnalysisInsightsContentHeader {
    font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #000000D9;
}

.auraAnalysisInsightsButton {
    border-radius: 2px;
    border: none;
    background: var(--Primary-6, #B64CFF);

    color: #FFFFFFD9;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    width: 100%;
}

.auraAnalysisInsightsButton:hover {
    background-color: #c477f7 !important;
    color: #FFFFFFD9 !important;
}


.auraAnalysisInsightsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding-left: 8px;
}

.elementFlexHorizontalAura {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 10px;
    justify-content: space-between;
  }

  .elementFlexVerticalAura {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }