.highCloudCoverageTitleAura {
  
  color: var(--Primary-5, #B64CFF);
  text-align: center;
  
  /* H3/medium */
  font-family: Outfit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 128.571% */
  
}

.iconLarge {
  width: 63px;
  height: 63px;
}



.highCloudCoverageDescriptionContainer {
  display: flex;
  gap: 4px;
  margin-top: 4px;
  align-items: center;
  font-family: Outfit;
}

.highCloudCoverageDescriptionAura {
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.65));
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  
  /* Footnote/description */
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
   /* 133.333% */
  text-align: center;
}


.cultureEstimateAura {
  font-size: 12px;
  font-weight: 400;
  color: #000000A6;
  line-height: 16px;
  font-family: Outfit;
  
  
}

.cultureEstimateValue {
  color: #B64CFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Outfit;
}

.radialBarChart {
  position: relative;
  transform: translateX(-12%); 
  width: 94%; 
  
}

.productivityContainer {
  font-size: 24px

}

.plantingHarvestTitle{
  font-size: 12px;
  font-weight: 400;
  color: #000000A6;
  line-height: 16px;
  font-family: Outfit;
}

.plantingHarvestValue{
  color: #B64CFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Outfit;
}

.auraProductivityValue{ 
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #242424;
}

.HeaderProductivityValue{
  font-family: Outfit;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #242424;

  min-width: 180px;
}

.customCardFirstItem {
  width: 100%;
  padding: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.customCardFirstContent {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.auraFirstRiskScore {
  display: inline-block;
  min-width: 64px;
  width: 64px;
  min-height: 64px;
  height: 64px;
  /* line-height: 64px; */
  border-radius: 50%;
  color: #FFFFFFD9;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 64px;
  text-align: center;
}

.auraOtherRiskScore {
  display: inline-block;
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  /* line-height: 64px; */
  border-radius: 50%;
  color: #FFFFFFD9;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}

.auraSafra {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000A6;
}

.customCardOtherItem {
  width: 100%;
  padding: 23.5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  background-color: #FFFFFF;
  height: 216px;
}