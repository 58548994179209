.esgCardContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  height: 316px;
  gap: 16px;
  justify-content: center;
  max-width: 1680px; /* Largura suficiente para 5 cards, incluindo a separação */
  margin: auto; /* Centraliza o container na página */
  overflow: auto;
}

.esgCard {
  width: 256px;
  padding-bottom: 24px;
  padding-top: 12px;
  height: 100px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.esgCardIcon {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.esgCardContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

