.billingTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
  margin-bottom: 52px;
  overflow: hidden;
}
