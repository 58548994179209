.sensePlusPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.sensePlusMapPopoverInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
}

.sensePlusProposalIdTitle {
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.sensePlusProposalId {
  color: var(--Primary-5, #B64CFF);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sensePlusPopupArea {
  color: var(--Character-Secondary-65, rgba(255, 255, 255, 0.65));

  /* Nunito Sans/Body/Large */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.sensePopupInfoText {
  display: flex;
  gap: 4px;
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));

  /* Nunito Sans/Label/Large */
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.sensePlusPopupButton {
  max-width: 168px !important;
  color: var(--Character-Primary-85, rgba(255, 255, 255, 0.85));
  background-color: var(--Neutral-1, #141414);
  text-align: center;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;

  /* Footnote/description */
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.sensePlusPopupButton:hover {
  color: #9236d9 !important;
  background-color: var(--Neutral-1, #141414) !important;
}

.sensePlusPopup {
  max-width: none !important;
}

.sensePlusPopup .mapboxgl-popup-content {
  width: 288px;
  height: auto;
  border-radius: 4px;
  background: var(--Neutral-1, #141414);
  padding: 16px 16px 8px 16px; /* Top, Right, Bottom, Left */
  color: #ffffff; /* Texto branco */
}

.sensePlusPopup .mapboxgl-popup-tip {
  border-top-color: var(--Neutral-1, #141414); /* Cor do triângulo do popup */
}

.sensePlusPopup .mapboxgl-popup-close-button {
  color: #ffffffd9 !important; /* Botão de fechar branco */
}