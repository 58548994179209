.mapContainer {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}

.skeletonInput {
  width: 200px;
}

.visionCarCode {
  display: inline-block;
  margin-right: 4px;
  word-break: keep-all;
  color: #9236D9;
}

.printButton,
.favoriteButton {
  margin-left: 10px;
}

.mapContainerWrapper {
  padding: 4px;
  height: 512px;
  position: relative;
}

.visionReportElementCard {
  padding: 4px;
  height: 512px;
  width: 100%;
  position: relative;

  border-radius: 8px;
  background: var(--Neutral-1, #FFF);

  /* drop-shadow/0.15 */
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.mapOverlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-radius: 6px;
}

/* Custom tab text color */
.tabText {
  color: black;
/* H4/medium */
font-family: Outfit;
font-size: 28px!important;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
}

.ant-tabs-tab.ant-tabs-tab-active .tabText {
  color: #9236D9; /* Purple color when selected */
}

.ant-tabs-tab:hover .tabText {
  color: #9236D9; /* Optional: Purple color on hover */
}

/* Ensuring the font size remains consistent */
.ant-tabs-tab .tabText {
  font-size: 18px;
}

/* Remove redundant styles */
.ant-tabs-tab {
  color: black !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--Primary-6, #9236D9) !important;
}

.ant-tabs-ink-bar {
  background: var(--Primary-6, #9236D9) !important;
}

.visionMapTwoTitle {
  margin-top: 16px;

  color: var(--Primary-5, #B64CFF);

  /* H4/medium */
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.visionLegendText {
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: 'liga' off;
  
  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.visionMapLegendCard {
  position: absolute;
  top: 24px;
  left: 24px;
  background-color: white;
  padding: 16px 24px 16px 24px;
  border-radius: 4px;
  min-width: 224px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.visionMapLegendContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.visionMapLegendSquare {
  width: 24px;
  height: 24px;
  border-radius: 2px;
}

.visionMapLegendLine {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.visionMapLegendTitle {
  color: var(--Character-Title-85, rgba(0, 0, 0, 0.85));

  /* H5/medium */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}