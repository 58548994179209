.farmInfoContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.farmInfoMapContainer {
  height: 148px;
}

.farmInfoRiskChartContainer {
  display: grid;
  height: 0px;
}

.farmInfoRiskChart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width:  148px;
  height: 148px;
  background-color: white;
  border-radius: 50%;
  justify-self: center;
  top: -68px;
}

.farmInfoTextContainer {
  padding: 88px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.farmInfoTextColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: 12vw;
}

.farmInfoTextTitle {
  font-size: 12px;
  font-weight: 600;
  line-height: 0px;
  color: #000000a6;
  margin-top: 20px;
}

.farmInfoText {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;

  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.farmInfoTextPurple {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;

  color: var(--Primary-5, #B64CFF);

  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.vl {
  border-left: 2px solid #ccc;
  height: auto;
  margin: 0px 16px;
}

.errorCircle {
  display: flex;
  justify-content: center;
  width: 130px;
  height: 130px;
  background-color: #F5222D;
  border-radius: 50%;
}

.farmInfoTextStatus {
  /* H5/medium */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}