.distributionCarChartCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: white;
    width: 100%;
    min-width: 240px;
    padding: 0px;
    display: "flex",
  }
  
  .distributionCarChartTitle {
    padding: 24px;
  
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* H5/medium */
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  
  .distributionCarChartContent {
    padding: 16px 24px 16px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  
  .distributionCarChartContainer {
    min-width: 100px;
  }
  
  .distributionCarChart{
    width: 50%;
    max-height: 340px;
    aspect-ratio: 1;
  }
  
  .distributionCarChartLegend {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
  
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    font-kerning: none;
    font-feature-settings: 'liga' off;
    
    /* Body/regular */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
  .distributionCarChartLegendLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .distributionCarChartLegendDot {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    min-width: 176px;
  }

  .distributionCarChartLegendTitle {
    min-width: 176px;
    
    color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));

    /* Body/semibold */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .distributionCarChartLegendDescription {
    color: var(--Character-Secondary-65, rgba(0, 0, 0, 0.65));
    font-kerning: none;
    font-variant-numeric: lining-nums proportional-nums;
    
    /* Footnote/description */
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  
  .distributionCarChartLegendAlignRight {
    text-align: right;
    min-width: 68px;
  }