/* Login container */
.LoginButton {
  display: flex;
  width: 114px;
  height: 42px;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #9236d9;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--character-title-inverse-85, rgba(255, 255, 255, 0.85));
}

.LoginButton:hover {
  color: #fff !important;
  background-color: #9236d9 !important;
}

.LoginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 60px;
  background-color: #fafafa;
}

.LoginContainer .LoginCard {
  border-radius: 4px;
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.LoginContainer h2 {
  margin-bottom: 48px;
  align-self: center;
  color: var(--Colors-Primary-800, #8900c5);
  text-align: center;

  /* H1/medium */
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
}

.LoginForgottenPass {
  border: none !important;
  color: var(--Primary-6, var(--Primary-6, #9236d9));
  text-align: center;

  /* H5/regular */
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.LoginForgottenPass:hover {
  color: var(--Primary-6, var(--Primary-6, #b07cd8)) !important;
}

.forgottenPassPopup {
  max-width: 236px;
  padding-left: 16px;
  padding-right: 16px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;

  /* Footnote/description */
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.sendEmailButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.sendEmailButtonIcon {
  color: #9236d9;
  border: none;
}

.sendEmailButtonIcon:hover {
  color: var(--Primary-6, var(--Primary-6, #b07cd8)) !important;
}
