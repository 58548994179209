.error-popover-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent background */
  z-index: 1000; /* Ensure it stays above the map */
  border-radius: 6px;
}

.error-popover-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.error-popover-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 390px;
  width: 600px;
  background-color: #fff; /* Opaque background for text card */
  border-radius: 6px;
  padding: 20px;
}

.errorIcon {
  font-size: 50px;
  color: #f5222d;
  margin-bottom: 16px;
}

.errorTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.errorDescription {
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
}

.error-button {
  background-color: #9236d9;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  margin-bottom: 10px;
}

.error-popover-card-content{
  display: flex;
      flex-direction: column;
      align-items: center;

}