.auraMapContainer {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    width: 100%;
    gap: 8px;
}

.auraMapVis {
    width: 100%;
    height: 560px;
    position: relative;
}

.auraMapButtonLabel {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;

    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #000000A6;
}

.auraMapLoading {
    position: absolute; /* Permite sobrepor outros elementos */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
  
.auraMapGraphContainer {
    padding: 16px 8px 8px;
}