.plantingWindowContainer {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  height: 100%;
  flex:1;
}

.plantingWindowTitle {
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
}

.dividerCustom {
  margin: 8px;
}

.infoSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cloudIconLarge {
  font-size: 44px;
  color: #00000040;
}

.infoIcon {
  color: #b54cff;
}

.textInfoTitle {
  color: var(--Neutral-10, #262626);

  font-family: "Outfit", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textInfo {
  color: #b64cff;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.zarcSection {
  display: flex;
  flex-direction: column;
  padding: 0px 8px 0px 8px;
}

.zarcTitle {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  
  color: #000000A6;
}

.zarcTextInfo{
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000A6;
}

.zarcInfoLine{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}