.esgCardContainerSenseBasic {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  height: 560px;
  gap: 16px;
  justify-content: start;
  max-width: 1680px;
  margin: auto;
  overflow: auto;
}

.esgCardSenseBasic {
  width: 280px;
  padding-bottom: 1px;
  padding-top: 1px;
  height: 80px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.esgCardContentSenseBasic {
  display: flex;
  align-items: center;
  gap: 14px;
  color: var(--Character-Primary-85, rgba(0, 0, 0, 0.85));
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.esgCardIconSenseBasic {
  flex-shrink: 0;
  font-size: 20px;
}

.esgCardTextSenseBasic {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.auraEsgAltContainer {
  width: 100%;
  min-width: 240px;
  padding: 16px 8px 16px 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #FFFFFF;
}

.auraEsgAltCardTitle {
  font-family: Outfit;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #000000D9;
}

.auraEsgAltContent {
  display: flex;
  flex-direction: column;
  max-height: 504px;
  height: 100%;
  width: 100%;
  gap: 8px;
  overflow-y: scroll;
  align-items: center;
}

.auraEsgAltCard {
  width: calc(100% - 8px);
  padding: 16px 8px 16px 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: left;
  text-align: center;
  background-color: #FFFFFF;
}


.auraEsgAltCardIcon {
  display: flex;
  font-size: 20px;
}

.auraEsgAltCardText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #000000D9;
}