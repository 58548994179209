.visionDashboardFilterContainer {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 320px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.visionDashboardFilterCard {
  width: 100%;
  background: var(--Neutral-2, #262626);
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 8px 24px 24px 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--Character-Title-85, #ffffffd9);

  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 140% */
}

.visionDashboardFilterTitle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  color: var(--Character-Title-85, #ffffffd9);

  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 140% */
}

.visionDashboardLegendContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.visionDashboardLegendText {
  color: var(--Character-Title-85, #ffffffd9);

  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 140% */
}

.visionDashboardSegmented {
  position: absolute;
  top: 24px;
  left: calc(50% - 24px);
  transform: translateX(-50%);
  z-index: 1000;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  color: var(--Character-Secondary-65, rgba(0, 0, 0, 0.65));
  text-align: center;
  font-kerning: none;
  font-feature-settings: "liga" off;

  /* Body/regular */
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.visionDashboardSegmented .ant-segmented-item-selected {
  background-color: #8c8c8c !important;
  color: white !important;
}

.visionDashboardStateSelect {
  width: 160px;
}

.visionDashboardStateSelect.ant-select-single .ant-select-selector {
  background-color: #333;
  color: white;
  border: 1px solid #444;
}

.visionDashboardStateSelect .ant-select-arrow {
  color: white;
}

.visionDashboardStateSelect .ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.visionDashboardStateSelect .ant-select-dropdown {
  background-color: #333 !important;
}

.visionDashboardStateSelect .ant-select-dropdown .ant-select-item {
  background-color: #333 !important;
  color: white !important;
}

.visionDashboardStateSelect .ant-select-dropdown .ant-select-item-option-selected {
  background-color: #444 !important;
  color: white !important;
}
